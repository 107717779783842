
import pageStore from '@/store/modules/pageList'
import store from '@/store'

export const pageMixin = {
    data() {
        return {
            pageSize: 10,
            totalPage: 0,
            currentPage: 1,
            totalRow: 0,
        }
    },

    created() {
        if (! store._modulesNamespaceMap[this.$options.name + '/']) {
            store.registerModule(this.$options.name, pageStore.getOptions())
        }
    },

    computed: {
        searchDataConfig() {
            return this.$store.state[this.$options.name].search;
        }
    },


    mounted() {
        this.currentPage = this.$store.state[this.$options.name].currentPage
        this.$bus.$on('onSearch', this.handleSearch)
        this.$bus.$on('resetSearch', this.handleResetSearch)
    },


    methods: {
        handleSearch(search) {
            this.$store.commit(`${this.$options.name}/SET_SEARCH`,search)
        },
        handleResetSearch() {
            this.$store.commit(`${this.$options.name}/SET_SEARCH`, {})
        }
    }
}
